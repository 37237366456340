define("discourse/plugins/discourse-ai/discourse/templates/preferences/ai", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="control-label">{{i18n "discourse_ai.title"}}</label>
  
  {{#if this.canToggleAutoImageCaption}}
    <div class="control-group ai-setting">
      <PreferenceCheckbox
        @labelKey="discourse_ai.ai_helper.image_caption.automatic_caption_setting"
        @checked={{this.model.user_option.auto_image_caption}}
        data-setting-name="auto-image-caption"
        class="pref-auto-image-caption"
      />
    </div>
  
    <SaveControls
      @id="user_ai_preference_save"
      @model={{this.model}}
      @action={{this.save}}
      @saved={{this.saved}}
    />
  {{else}}
    <EmptyState
      @title={{i18n "discourse_ai.user_preferences.empty_state.title"}}
      @body={{i18n "discourse_ai.user_preferences.empty_state.body"}}
    />
  {{/if}}
  */
  {
    "id": "whXJK4wq",
    "block": "[[[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"discourse_ai.title\"],null]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"canToggleAutoImageCaption\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group ai-setting\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,\"data-setting-name\",\"auto-image-caption\"],[24,0,\"pref-auto-image-caption\"]],[[\"@labelKey\",\"@checked\"],[\"discourse_ai.ai_helper.image_caption.automatic_caption_setting\",[30,0,[\"model\",\"user_option\",\"auto_image_caption\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@id\",\"@model\",\"@action\",\"@saved\"],[\"user_ai_preference_save\",[30,0,[\"model\"]],[30,0,[\"save\"]],[30,0,[\"saved\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,4],null,[[\"@title\",\"@body\"],[[28,[37,0],[\"discourse_ai.user_preferences.empty_state.title\"],null],[28,[37,0],[\"discourse_ai.user_preferences.empty_state.body\"],null]]],null],[1,\"\\n\"]],[]]]],[],false,[\"i18n\",\"if\",\"preference-checkbox\",\"save-controls\",\"empty-state\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/templates/preferences/ai.hbs",
    "isStrictMode": false
  });
});